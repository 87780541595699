<template>
  <teleport to="body">
    <div v-if="open" class="modal">
      <div class="product">

        <div class="content">
          <font-awesome-icon class="exit" icon="fa-circle-xmark" @click="open = ! open" />
          <h3 class="title">Create/Edit Modifier</h3>
          <label>Name</label>
          <input type="text" v-model="currentModifier.name" placeholder="Name"/>

          <label>Active / Available Modifier</label>
          <Toggle class="toggle" onLabel="Available" offLabel="Unavailable"  v-model="currentModifier.active" />

          <label>Description</label>
          <quill-editor theme="snow" toolbar="essential" contentType="text" v-model:content="currentModifier.desc" />

          <label>Quantity</label>
          <input type="number" v-model.number="currentModifier.quantity" placeholder="0"/>

          <label>Modifier Price</label>
          <input type="number" v-model.number="currentModifier.price" placeholder="0"/>

        </div>

        <div class="actions">
          <button class="action danger" @click.prevent.stop="remove">Delete</button>
          <button class="action cancel cancel-text" @click.prevent.stop="cancel">Cancel</button>
          <button class="action primary" @click.prevent.stop="commit">Save</button>
        </div>

      </div>

    </div>
  </teleport>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Toggle from '@vueform/toggle';

export default {
  name: 'ModifierModal',
  props: ['show', 'modifier'],
  emits: ['save', 'remove'],
  components: {
    QuillEditor,
    Toggle,
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    remove() {
      this.open = ! this.open;
      this.$emit('remove', this.currentModifier);
      this.open = false;
    },
    cancel() {
      this.open = ! this.open;
    },
    commit() {
      //console.log(this.currentModifier)
      this.$emit('save', this.currentModifier);
      this.open = false;
    },
  },
  watch: {
    show() {
      this.open = true;
    },
    modifier() {
      this.currentModifier = this.modifier;
    }
  }
}

</script>


<style lang="scss" >
@import "public/wrapper";


</style>
